const state = {
  language: '',
  is_login: false,
  is_in_alert: false,
  show_drawer: true,
  show_modal: false,
  device_id: '',
  user_id: '',
  user_token: '',
  user_sections:{
    offers: false,
    rewards: false,
    favorites: false,
    invitations: false,
    room_bookings: false,
    locations: false,
    personal_trainer: false,
    news: false,
    events: false,
    company_connections: false,
    us: false,
    contact_us: false,
    inside_request: false
  },
  monitored_companies: [],
  selected_company: null,
  loader: false,
  email: '',
  sos_alert: false,
  current_section: {icon:"security-camera-icon", title:"CCTV"},
  company_sections: [{icon:"security-camera-icon", title:"CCTV"}],
  show_company_sections: false,
  show_speakers: false,
  invitations_form: false,
  open_doors: false,
  current_events:[],
  filter_companies:[],
  show_comment_event_modal: false,
  alert_message: '',
  show_events_reports:false
}

const getters = {
  getUserToken (state) { return state.user_token },
  getDeviceId (state) { return state.device_id },
  getLanguage (state) { return state.language },
  getShowDrawer (state) { return state.show_drawer },
  getShowModal (state) { return state.show_modal },
  getValidationOffers (state) {return state.user_sections.offers},
  getValidationRewards (state) {return state.user_sections.rewards},
  getValidationFavorites (state) {return state.user_sections.favorites},
  getValidationInvitations (state) {return state.user_sections.invitations},
  getValidationRoomBookings (state) {return state.user_sections.room_bookings},
  getValidationLocations (state) {return state.user_sections.locations},
  getValidationPersonalTrainer (state) {return state.user_sections.personal_trainer},
  getValidationNews (state) {return state.user_sections.news},
  getValidationEvents (state) {return state.user_sections.events},
  getValidationCompanyConnections (state) {return state.user_sections.company_connections},
  getValidationUs (state) {return state.user_sections.us},
  getValidationContactUs (state) {return state.user_sections.contact_us},
  getValidationInsideRequest (state) {return state.user_sections.inside_request},
  getIsLogin(state) { return state.is_login },
  getIsInAlert(state) { return state.is_in_alert },
  getUserId(state) { return state.user_id },
  getMonitoredCompanies(state) { return state.monitored_companies },
  getSelectedCompany(state) { return state.selected_company },
  getLoader(state) { return state.loader },
  getEmail(state) { return state.email },
  getSOSAlert(state) { return state.sos_alert },
  getCurrentSection(state) { return state.current_section },
  getCompanySections(state) { return state.company_sections },
  getShowCompanySections(state) { return state.show_company_sections },
  getShowSpeakers(state) { return state.show_speakers },
  getShowInvitationsForm(state) { return state.invitations_form },
  getShowOpenDoors(state) { return state.open_doors },
  getCurrentEvents(state) { return state.current_events },
  getFilterCompanies(state) { return state.filter_companies },
  getShowCommentEventModal(state) { return state.show_comment_event_modal },
  getAlertMessage(state) { return state.alert_message },
  getShowEventsReport(state) { return state.show_events_reports}
}

const mutations = {
  setLanguage(state, payload) { state.language = payload },
  setShowDrawer(state, payload) { state.show_drawer = payload },
  setShowModal(state, payload) { state.show_modal = payload },
  setCompany(state, payload) { state.company = payload },
  setUserToken(state, payload) { state.user_token = payload },
  setDeviceId(state, payload) { state.device_id = payload },
  setValidationOffers (state, payload) { state.user_sections.offers = payload},
  setValidationRewards (state, payload) { state.user_sections.rewards = payload},
  setValidationFavorites (state, payload) { state.user_sections.favorites = payload},
  setValidationInvitations (state, payload) { state.user_sections.invitations = payload},
  setValidationRoomBookings (state, payload) { state.user_sections.room_bookings = payload},
  setValidationLocations (state, payload) { state.user_sections.locations = payload},
  setValidationPersonalTrainer (state, payload) { state.user_sections.personal_trainer = payload},
  setValidationNews (state, payload) { state.user_sections.news = payload},
  setValidationEvents (state, payload) { state.user_sections.events = payload},
  setValidationCompanyConnections (state, payload) { state.user_sections.company_connections = payload},
  setValidationUs (state, payload) { state.user_sections.us = payload},
  setValidationContactUs (state, payload) { state.user_sections.contact_us = payload},
  setValidationInsideRequest (state, payload) { state.user_sections.inside_request = payload},
  setIsLogin: function(state, payload) { state.is_login = payload },
  setIsInAlert: function(state, payload) { state.is_in_alert = payload },
  setUserIdEncrypt: function (state, payload) {
    state.user_id_encrypt = payload
    sessionStorage.setItem('user_id_encrypt', payload)
  },
  setUserType(state, payload) { state.user_type = payload },
  setUserId(state, payload) { state.user_id = payload },
  setMonitoredCompanies(state, payload) { state.monitored_companies = payload },
  setSelectedCompany(state, payload) { state.selected_company = payload },
  setLoader(state, payload) { state.loader = payload },
  setEmail(state, payload) { state.email = payload },
  setSOSAlert(state, payload) { state.sos_alert = payload },
  setCurrentSection(state, payload) { state.current_section = payload },
  setCompanySections(state, payload) { state.company_sections = payload },
  setShowCompanySections(state, payload) { state.show_company_sections = payload },
  setShowSpeakers(state, payload) { state.show_speakers = payload },
  setShowInvitationsForm(state, payload) { state.invitations_form = payload },
  setShowOpenDoors(state, payload) { state.open_doors = payload },
  setCurrentEvents(state, payload) { state.current_events = payload },
  setFilterCompanies(state, payload) { state.filter_companies = payload },
  setShowCommentEventModal(state, payload) { state.show_comment_event_modal = payload },
  setAlertMessage: function(state, payload) { state.alert_message = payload },
  setShowEventsReport(state, payload) { state.show_events_reports = payload },
}

const actions = {
  updateUserToken({commit}, payload) { commit('setUserToken', payload) },
  updateLanguage({commit}, payload) { commit('setLanguage', payload) },
  updateShowDrawer({commit}, payload) { commit('setShowDrawer', payload) },
  updateShowModal({commit}, payload) { commit('setShowModal', payload) },
  updateCompany({commit}, payload) { commit('setCompany', payload) },
  updateDeviceId({commit}, payload) { commit('setDeviceId', payload)  },
  updateValidationOffers ({commit}, payload) {commit('setValidationOffers', payload)},
  updateValidationRewards ({commit}, payload) {commit('setValidationRewards', payload)},
  updateValidationFavorites ({commit}, payload) {commit('setValidationFavorites', payload)},
  updateValidationInvitations ({commit}, payload) {commit('setValidationInvitations', payload)},
  updateValidationRoomBookings ({commit}, payload) {commit('setValidationRoomBookings', payload)},
  updateValidationLocations ({commit}, payload) {commit('setValidationLocations', payload)},
  updateValidationPersonalTrainer ({commit}, payload) {commit('setValidationPersonalTrainer', payload)},
  updateValidationNews ({commit}, payload) {commit('setValidationNews', payload)},
  updateValidationEvents ({commit}, payload) {commit('setValidationEvents', payload)},
  updateValidationCompanyConnections ({commit}, payload) {commit('setValidationCompanyConnections', payload)},
  updateValidationUs ({commit}, payload) {commit('setValidationUs', payload)},
  updateValidationContactUs ({commit}, payload) {commit('setValidationContactUs', payload)},
  updateValidationInsideRequest ({commit}, payload) {commit('setValidationInsideRequest', payload)},
  updateIsLogin: function({ commit }, payload) { commit('setIsLogin', payload ) },
  updateIsInAlert: function({ commit }, payload) { commit('setIsInAlert', payload ) },
  updateUserIdEncrypt: function ({commit}, payload) { commit('setUserIdEncrypt', payload) },
  updateUserType: function ({commit}, payload) { commit('setUserType', payload) },
  updateUserId({ commit }, payload) { commit('setUserId', payload) },
  updateMonitoredCompanies({ commit }, payload) { commit('setMonitoredCompanies', payload) },
  updateSelectedCompany({ commit }, payload) { commit('setSelectedCompany', payload) },
  updateLoader({ commit }, payload) { commit('setLoader', payload) },
  updateEmail({ commit }, payload) { commit('setEmail', payload) },
  updateSOSAlert({ commit }, payload) { commit('setSOSAlert', payload) },
  updateCurrentSection({ commit }, payload) { commit('setCurrentSection', payload) },
  updateCompanySections({ commit }, payload) { commit('setCompanySections', payload) },
  updateShowCompanySections({ commit }, payload) { commit('setShowCompanySections', payload) },
  updateShowSpeakers({ commit }, payload) { commit('setShowSpeakers', payload) },
  updateShowInvitationsForm({ commit }, payload) { commit('setShowInvitationsForm', payload) },
  updateShowOpenDoors({ commit }, payload) { commit('setShowOpenDoors', payload) },
  updateCurrentEvents({ commit }, payload) { commit('setCurrentEvents', payload) },
  updateFilterCompanies({ commit }, payload) { commit('setFilterCompanies', payload) },
  updateShowCommentEventModal({ commit }, payload) { commit('setShowCommentEventModal', payload) },
  updateAlertMessage({ commit }, payload) { commit('setAlertMessage', payload) },
  updateShowEventsReport({ commit }, payload) { commit('setShowEventsReport', payload)  },
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}

