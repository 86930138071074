<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 799 799">
    <path id="Path_20" data-name="Path 20"
      d="M0,399.5C0,178.86,178.86,0,399.5,0s399.5,178.86,399.5,399.5-178.86,399.5-399.5,399.5S0,620.14,0,399.5Z"
      style="fill: #C4DAF5; stroke-width: 0px;" />
    <g style="fill: #343434; stroke-width: 0px; transform: translate(100px, 100px) scale(1.2);">
      <path d="M404.587,35.439h-89.6V9.5c0-5.246-4.254-9.5-9.5-9.5H180.913c-5.246,0-9.5,4.254-9.5,9.5v25.939h-89.6
        c-5.246,0-9.5,4.254-9.5,9.5V476.9c0,5.246,4.254,9.5,9.5,9.5h322.774c5.246,0,9.5-4.254,9.5-9.5V44.939
        C414.087,39.692,409.833,35.439,404.587,35.439z M295.987,73.749H190.413V19h105.574V73.749z M395.087,467.4H91.313V54.439h80.1
        V83.25c0,5.246,4.254,9.5,9.5,9.5h124.574c5.246,0,9.5-4.254,9.5-9.5V54.439h80.1V467.4z" />
      <path d="M278.159,141.596H131.618c-5.246,0-9.5-4.254-9.5-9.5s4.254-9.5,9.5-9.5h146.541c5.246,0,9.5,4.254,9.5,9.5
        S283.405,141.596,278.159,141.596z" />
      <path d="M357.656,190.443H131.618c-5.246,0-9.5-4.254-9.5-9.5s4.254-9.5,9.5-9.5h226.038c5.246,0,9.5,4.254,9.5,9.5
		S362.902,190.443,357.656,190.443z" />
      <path d="M357.656,239.291H131.618c-5.246,0-9.5-4.254-9.5-9.5s4.254-9.5,9.5-9.5h226.038c5.246,0,9.5,4.254,9.5,9.5
		S362.902,239.291,357.656,239.291z" />
      <path d="M357.656,285.264H131.618c-5.246,0-9.5-4.254-9.5-9.5s4.254-9.5,9.5-9.5h226.038c5.246,0,9.5,4.254,9.5,9.5
		S362.902,285.264,357.656,285.264z" />
      <path d="M357.656,141.596h-31.607c-5.246,0-9.5-4.254-9.5-9.5s4.254-9.5,9.5-9.5h31.607c5.246,0,9.5,4.254,9.5,9.5
		S362.902,141.596,357.656,141.596z" />
      <path style="fill: #343434;" d="M334.972,339.48c-9.281-9.186-22.102-14.868-36.265-14.868c-13.363,0-25.53,5.063-34.657,13.351
		l36.227,35.858L334.972,339.48z" />
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css"></style>
